export const removeDynamicSegments = (path: string) => {
  const pathnames = path.split("/").filter((x) => x)
  const isDynamicSegment = (value: string) => !isNaN(Number(value))

  return (
    "/" + pathnames.filter((segment) => !isDynamicSegment(segment)).join("/")
  )
}

export function joinUrl(...parts: string[]): string {
  return parts
    .map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/\/*$/, "")
      } else {
        return part.trim().replace(/(^\/*)|(\/*)$/g, "")
      }
    })
    .filter((x) => x.length)
    .join("/")
}
